<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRange"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days']"
                :max-selectable-days="30"
                :timezone="asup.timezone"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="onlyErrors"
                switch
              >
                Errors only
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-input
                v-model="search"
                size="sm"
                placeholder="Search for clients..."
              />
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-card title="Client Backups">
      <ClientMatrix
        v-if="asup && asup.id"
        :asup-id="asup.id"
        :only-errors="onlyErrors"
        :search="search"
        :time-range="timeRange"
        :default-rows-per-page="50"
        :full-height="false"
        type="primary"
      />
    </b-card>

    <b-card title="Client Replications">
      <ClientMatrix
        v-if="asup && asup.id"
        :asup-id="asup.id"
        :only-errors="onlyErrors"
        :search="search"
        :time-range="timeRange"
        :default-rows-per-page="50"
        :full-height="false"
        type="secondary"
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import ClientMatrix from './components/ClientMatrix.vue'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    DateRangePicker,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInput,
    ClientMatrix,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'last7Days',
      },
      onlyErrors: false,
      search: null,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
  },
  watch: {
    timeRange() {
      this.updateRoute()
    },
    onlyErrors() {
      this.updateRoute()
    },
    search() {
      this.updateRoute()
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment(this.$route.query.from).toDate(),
        endDate: moment(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === 'true') {
      this.onlyErrors = true
    } else if (this.$route.query.onlyErrors === 'false') {
      this.onlyErrors = false
    } else if (this.isCurrentUserHost === true) { // Set onlyErrors filter for host users automatically, if not explicitly set in query
      this.onlyErrors = true
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search
    }
  },
  methods: {
    updateRoute() {
      this.$router.replace({
        path: this.$route.path,
        query: {
          from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
          to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
          onlyErrors: this.onlyErrors,
          search: this.search,
        },
      }).catch(() => {})
    },
    onCellClick(e) {
      // eslint-disable-next-line no-unused-vars
      const { row, colIndex, day } = e

      if (row.properties.wfJobWorkflow) {
        // WF Row -> load WF data
        const { jobGroup } = row.properties
        const route = {
          name: 'avamar-detail.group-detail',
          query: {
            group: jobGroup,
            from: day.startOf('day').format('YYYY-MM-DD'),
            to: day.endOf('day').format('YYYY-MM-DD'),
          },
        }

        this.$router.push(route)
      } else {
        // Client Row -> load client data
        const clientName = row.displayName

        const route = {
          name: 'avamar-detail.client-detail',
          params: { clientName },
          query: {
            from: day.startOf('day').format('YYYY-MM-DD'),
            to: day.endOf('day').format('YYYY-MM-DD'),
          },
        }

        this.$router.push(route)
      }
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
